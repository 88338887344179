h3.soustitre_1 {
  color: #abcd1f;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  background: #fff;
  padding: 0.5rem 1rem;
  box-shadow: 0 0.2rem 0 0 rgba(188, 188, 188, 0.87); }

h4.soustitre_2 {
  color: #3b87ab;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  background: #fff;
  padding: 0.5rem 1rem;
  box-shadow: 0 0.2rem 0 0 rgba(188, 188, 188, 0.87); }

h5.intertitre {
  color: #3a86ab;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 1.5rem 0;
  padding-left: 1.2rem;
  position: relative; }

h4.intertitre_2 {
  color: #374862;
  font-weight: 400;
  font-size: 2.1rem;
  font-family: 'Roboto Condensed'; }

h4.intertitre_3 {
  color: #000;
  font-weight: 400;
  font-size: 2.1rem;
  font-family: 'Roboto Condensed';
  text-transform: uppercase; }

strong.accroche {
  color: #374862;
  font-weight: 700;
  font-style: italic;
  font-size: 1.5rem; }

strong.mise_en_avant_1 {
  font-weight: 700;
  color: #3a86ab; }

strong.mise_en_avant_2 {
  font-weight: 700;
  color: #609940; }

span.legende {
  font-weight: normal;
  font-style: italic;
  color: #949494;
  font-size: 1.2rem; }

blockquote.citation {
  padding: 1.5rem;
  background: #3b87ab;
  color: #fff;
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 700;
  font-style: italic; }
