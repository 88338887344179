#zone-agenda .ma-news-container .bx-controls .bx-prev,
#zone-agenda .ma-news-container .bx-controls .bx-next, aside #actu-aside .ma-container .ma-all-news-link a, aside #actu-aside .ma-container .ma-pagination .bx-pager-item a, aside #short-links > div a, aside #short-links > div a .infobulle, #short-links > div a, #short-links > div a .infobulle {
  transition: all 0.4s ease-in-out; }

#zone-agenda .ma-news-container .bx-controls .bx-prev,
#zone-agenda .ma-news-container .bx-controls .bx-next, aside #actu-aside .ma-container .ma-all-news-link a, aside #actu-aside .ma-container .ma-pagination .bx-pager-item a, aside #short-links > div a, aside #short-links > div a .infobulle, #short-links > div a, #short-links > div a .infobulle {
  transition: all 0.4s ease-in-out; }

#zone-agenda {
  height: 17.5rem;
  background: #f8f8f8;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
  padding-top: 1rem;
  position: relative;
  z-index: 12; }
  #zone-agenda .container-rel {
    position: relative; }
  #zone-agenda h4 {
    text-align: center;
    text-transform: uppercase;
    color: #919191;
    font-weight: 400;
    font-family: "Roboto Condensed", Arial, Tahoma, Verdana, Helvetica, sans-serif;
    position: relative;
    display: block;
    margin-bottom: 1rem; }
    #zone-agenda h4::before, #zone-agenda h4::after {
      content: "";
      position: absolute;
      top: 50%;
      height: 0.1rem;
      background: #d9d9d9;
      width: 40%; }
    #zone-agenda h4::before {
      left: 0; }
    #zone-agenda h4::after {
      right: 0; }
  #zone-agenda .ma-news-container, #zone-agenda .ma-news-container .bx-viewport {
    height: 13rem !important; }
  #zone-agenda .ma-news-container .ma-news {
    height: 11.5rem;
    border: 1px solid #dcdcdc;
    display: inline-block;
    vertical-align: top;
    background: linear-gradient(135deg, white 67%, #e6e6e6 100%);
    font-family: "Roboto Condensed", Arial, Tahoma, Verdana, Helvetica, sans-serif;
    position: relative; }
    #zone-agenda .ma-news-container .ma-news .ma-news-content {
      height: 100%;
      vertical-align: top;
      display: inline-block;
      width: 100%; }
    #zone-agenda .ma-news-container .ma-news::after {
      content: "";
      position: absolute;
      bottom: -1.2rem;
      width: 36rem;
      height: 2.5rem;
      left: -1.5rem;
      background-image: url("../../images/raspail/shadow_actu.png");
      z-index: -1; }
    #zone-agenda .ma-news-container .ma-news .ma-news-image {
      width: 11.8rem;
      height: 11.3rem;
      float: left;
      position: relative;
      overflow: hidden; }
      #zone-agenda .ma-news-container .ma-news .ma-news-image::before, #zone-agenda .ma-news-container .ma-news .ma-news-image::after {
        content: "";
        position: absolute; }
      #zone-agenda .ma-news-container .ma-news .ma-news-image::after {
        right: 0;
        top: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 115px 53px;
        border-color: transparent transparent #fff transparent;
        z-index: 10; }
      #zone-agenda .ma-news-container .ma-news .ma-news-image::before {
        z-index: 5;
        right: -2.2rem;
        top: 0;
        width: 5.3rem;
        height: 11.5rem;
        background: #3b87ab;
        transform: skewX(-29deg); }
    #zone-agenda .ma-news-container .ma-news .ma-news-date:first-child,
    #zone-agenda .ma-news-container .ma-news .ma-news-date:first-child + .ma-news-title,
    #zone-agenda .ma-news-container .ma-news .ma-news-date:first-child + .ma-news-title + .ma-news-resume {
      margin-left: 2rem; }
    #zone-agenda .ma-news-container .ma-news .ma-news-date {
      margin-top: 1.3rem;
      color: #8d8d8d;
      text-transform: uppercase;
      font-size: 1rem; }
    #zone-agenda .ma-news-container .ma-news .ma-news-title {
      position: relative;
      padding-left: 1rem;
      overflow: auto; }
      #zone-agenda .ma-news-container .ma-news .ma-news-title::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0.8rem;
        width: 0.4rem;
        height: 1.1rem;
        background: #a0c623; }
      #zone-agenda .ma-news-container .ma-news .ma-news-title, #zone-agenda .ma-news-container .ma-news .ma-news-title a {
        text-decoration: none;
        color: #3b87ab;
        font-size: 1.6rem;
        font-weight: 400;
        text-decoration: none; }
    #zone-agenda .ma-news-container .ma-news .ma-news-resume {
      font-family: Arial, Tahoma, Verdana, Helvetica, sans-serif;
      font-size: 1.2rem;
      font-weight: 400;
      color: #8d8d8d;
      padding-right: 1.5rem; }
    #zone-agenda .ma-news-container .ma-news .ma-news-know-more-link {
      display: none; }
  #zone-agenda .ma-news-container .bx-controls {
    position: relative;
    top: -8rem;
    left: -3rem;
    width: 106%; }
    #zone-agenda .ma-news-container .bx-controls .bx-prev,
    #zone-agenda .ma-news-container .bx-controls .bx-next {
      width: 2.2rem;
      height: 2.2rem;
      transform: rotate(45deg) skew(20deg, 20deg);
      font-size: 0; }
      #zone-agenda .ma-news-container .bx-controls .bx-prev:hover,
      #zone-agenda .ma-news-container .bx-controls .bx-next:hover {
        width: 3rem;
        height: 3rem; }
    #zone-agenda .ma-news-container .bx-controls .bx-prev {
      float: left;
      border-left: 1px solid #a8b0ba;
      border-bottom: 1px solid #a8b0ba; }
      #zone-agenda .ma-news-container .bx-controls .bx-prev:hover {
        border-left: 1px solid #3b87ab;
        border-bottom: 1px solid #3b87ab; }
    #zone-agenda .ma-news-container .bx-controls .bx-next {
      float: right;
      border-top: 1px solid #a8b0ba;
      border-right: 1px solid #a8b0ba; }
      #zone-agenda .ma-news-container .bx-controls .bx-next:hover {
        border-top: 1px solid #3b87ab;
        border-right: 1px solid #3b87ab; }
  #zone-agenda .ma-all-news-link {
    display: none; }
  #zone-agenda .ma-pagination {
    display: none; }
  #zone-agenda .controls-next,
  #zone-agenda .controls-prev {
    position: absolute;
    transition: all 200ms ease; }
    #zone-agenda .controls-next svg,
    #zone-agenda .controls-prev svg {
      stroke: #a8b0ba; }
    #zone-agenda .controls-next:hover svg,
    #zone-agenda .controls-prev:hover svg {
      stroke: #3b87ab; }
  #zone-agenda .slider-container {
    padding: 0 5rem; }
    @media (min-width: 1441px) {
      #zone-agenda .slider-container {
        padding: 0; } }
  #zone-agenda .controls-prev {
    cursor: pointer;
    left: 2rem;
    top: 55%;
    transform: translate3d(0, -50%, 0); }
    #zone-agenda .controls-prev:hover {
      transform: translate3d(-5px, -50%, 0); }
    @media (min-width: 1025px) {
      #zone-agenda .controls-prev {
        left: 0rem; } }
    @media (min-width: 1441px) {
      #zone-agenda .controls-prev {
        left: -4rem; } }
  #zone-agenda .controls-next {
    cursor: pointer;
    right: 2rem;
    top: 55%;
    transform: translate3d(0, -50%, 0); }
    #zone-agenda .controls-next:hover {
      transform: translate3d(5px, -50%, 0); }
    @media (min-width: 1025px) {
      #zone-agenda .controls-next {
        right: 0rem; } }
    @media (min-width: 1441px) {
      #zone-agenda .controls-next {
        right: -4rem; } }

aside {
  padding-top: 2rem; }
  aside #actu-aside {
    background: #fff;
    border: 1px solid #dcdcdc;
    box-shadow: 0 0.2rem 0 0 rgba(188, 188, 188, 0.87);
    margin-bottom: 3.4rem; }
    aside #actu-aside > p {
      color: #283b57;
      margin: 0;
      padding: 1rem 0 1rem 1rem;
      border-bottom: 1px solid #dcdcdc;
      font-family: "Roboto Condensed", Arial, Tahoma, Verdana, Helvetica, sans-serif;
      font-size: 1.7rem; }
    aside #actu-aside .ma-container {
      padding: 1.5rem;
      height: 15rem; }
      aside #actu-aside .ma-container .ma-news-container {
        height: 10.6rem; }
      aside #actu-aside .ma-container .ma-news .ma-news-image {
        float: left;
        margin-right: 1.5rem; }
      aside #actu-aside .ma-container .ma-news .ma-news-date {
        color: #8d8d8d;
        text-transform: uppercase;
        font-size: 1rem; }
      aside #actu-aside .ma-container .ma-news .ma-news-title {
        position: relative;
        padding-left: 1rem;
        overflow: auto; }
        aside #actu-aside .ma-container .ma-news .ma-news-title::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0.8rem;
          width: 0.4rem;
          height: 1.1rem;
          background: #a0c623; }
        aside #actu-aside .ma-container .ma-news .ma-news-title, aside #actu-aside .ma-container .ma-news .ma-news-title a {
          text-decoration: none;
          color: #3b87ab;
          font-size: 1.4rem;
          font-weight: 400;
          text-decoration: none; }
      aside #actu-aside .ma-container .ma-news .ma-news-resume {
        font-family: Arial, Tahoma, Verdana, Helvetica, sans-serif;
        font-size: 1.2rem;
        font-weight: 400;
        color: #8d8d8d; }
      aside #actu-aside .ma-container .ma-news .ma-news-know-more-link {
        display: none; }
      aside #actu-aside .ma-container .ma-all-news-link {
        position: relative;
        display: inline-block; }
        aside #actu-aside .ma-container .ma-all-news-link a {
          color: #3a86ab;
          text-decoration: none;
          font-weight: 400;
          font-size: 1.2rem; }
          aside #actu-aside .ma-container .ma-all-news-link a:hover {
            color: #a0c623; }
      aside #actu-aside .ma-container .ma-pagination {
        float: right; }
        aside #actu-aside .ma-container .ma-pagination .bx-pager-item {
          display: inline-block;
          vertical-align: middle;
          margin-left: 1.5rem; }
          aside #actu-aside .ma-container .ma-pagination .bx-pager-item:last-child a::before {
            display: none; }
          aside #actu-aside .ma-container .ma-pagination .bx-pager-item a {
            text-decoration: none;
            color: #8d8d8d;
            font-size: 1.2rem;
            font-weight: 400;
            position: relative; }
            aside #actu-aside .ma-container .ma-pagination .bx-pager-item a:hover, aside #actu-aside .ma-container .ma-pagination .bx-pager-item a.active {
              color: #3b87ab; }
            aside #actu-aside .ma-container .ma-pagination .bx-pager-item a::before {
              content: "/";
              position: absolute;
              right: -0.8rem;
              top: -0.1rem; }
  aside iframe {
    max-width: 100%; }
  aside #short-links {
    margin-top: 2rem; }
    aside #short-links > p {
      color: #374862;
      font-size: 2.1rem;
      font-weight: 400;
      font-family: "Roboto Condensed", Arial, Tahoma, Verdana, Helvetica, sans-serif; }
    aside #short-links > div {
      background: whitesmoke url("../../images/raspail/bg_short_links.png") no-repeat 22rem;
      height: 7.5rem;
      padding: 2.5rem;
      border-bottom: 1px solid #bcbcbc; }
      aside #short-links > div a {
        display: inline-block;
        vertical-align: middle;
        width: 12.2rem;
        height: 3.2rem;
        text-decoration: none;
        background: #3b87ab;
        padding: 0.6rem 0 0 2.5rem;
        text-transform: uppercase;
        color: #fff;
        font-size: 1.4rem;
        font-family: "Roboto Condensed", Arial, Tahoma, Verdana, Helvetica, sans-serif;
        position: relative; }
        aside #short-links > div a .infobulle {
          position: absolute;
          opacity: 0;
          padding: 1rem;
          background: #374862;
          width: 12.2rem;
          font-size: 1.2rem;
          text-transform: none;
          left: 0;
          top: 3.4rem; }
          aside #short-links > div a .infobulle::before {
            content: "";
            position: absolute;
            top: -1rem;
            left: 5.6rem;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 7.5px 10px 7.5px;
            border-color: transparent transparent #374862 transparent; }
        aside #short-links > div a::before {
          content: "";
          position: absolute;
          left: 1rem;
          top: 1.1rem;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5.5px 0 5.5px 5px;
          border-color: transparent transparent transparent #fff; }
        aside #short-links > div a:hover {
          background: #a0c623; }
          aside #short-links > div a:hover .infobulle {
            opacity: 1;
            transform: translateY(1rem); }

#aside-playlists {
  margin-top: 2rem; }
  #aside-playlists .aside-playlists-wrapper {
    position: relative; }
  #aside-playlists .aside-playlists-list {
    height: 100%; }
  #aside-playlists .aside-playlists-customControls--prev, #aside-playlists .aside-playlists-customControls--next {
    cursor: pointer;
    height: 4rem;
    width: 2rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    transition: .4s ease-out; }
    #aside-playlists .aside-playlists-customControls--prev:hover, #aside-playlists .aside-playlists-customControls--next:hover {
      background: transparent; }
      #aside-playlists .aside-playlists-customControls--prev:hover::before, #aside-playlists .aside-playlists-customControls--next:hover::before {
        border-top: 0.1rem solid #374862;
        border-right: 0.1rem solid #374862; }
    #aside-playlists .aside-playlists-customControls--prev::before, #aside-playlists .aside-playlists-customControls--next::before {
      content: "";
      height: 1.5rem;
      width: 1.5rem;
      border-top: .1rem solid #ebebeb;
      border-right: .1rem solid #ebebeb;
      top: 50%;
      position: absolute;
      transition: .4s ease-out; }
  #aside-playlists .aside-playlists-customControls--prev {
    left: -4rem; }
    #aside-playlists .aside-playlists-customControls--prev::before {
      transform: translate(-50%, -50%) rotate(-135deg);
      left: 75%; }
  #aside-playlists .aside-playlists-customControls--next {
    right: -4rem; }
    #aside-playlists .aside-playlists-customControls--next::before {
      left: 25%;
      transform: translate(-50%, -50%) rotate(45deg); }
  #aside-playlists p {
    color: white;
    background: rgba(0, 0, 0, 0.56);
    position: absolute;
    bottom: 0;
    text-align: center;
    padding: .75rem .25rem;
    width: 100%;
    left: 0;
    font-size: 1.2rem; }
  #aside-playlists .bx-viewport {
    height: 16rem !important; }
  #aside-playlists .bx-wrapper {
    position: relative; }
    #aside-playlists .bx-wrapper:not(:last-child) {
      margin-bottom: 1rem !important; }
  #aside-playlists .bx-controls .bx-next {
    height: 4rem;
    width: 2rem;
    position: absolute;
    right: -4rem;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    transition: .4s ease-out; }
    #aside-playlists .bx-controls .bx-next:hover {
      background: transparent; }
      #aside-playlists .bx-controls .bx-next:hover::before {
        border-top: 0.1rem solid #374862;
        border-right: 0.1rem solid #374862; }
    #aside-playlists .bx-controls .bx-next::before {
      content: "";
      height: 1.5rem;
      width: 1.5rem;
      border-top: .1rem solid #ebebeb;
      border-right: .1rem solid #ebebeb;
      transform: translate(-50%, -50%) rotate(45deg);
      top: 50%;
      left: 25%;
      position: absolute;
      transition: .4s ease-out; }
  #aside-playlists .video {
    overflow: hidden;
    cursor: pointer;
    height: 100%; }
    #aside-playlists .video:hover::before, #aside-playlists .video:hover::after {
      opacity: 1; }
    #aside-playlists .video::before {
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      z-index: 1;
      opacity: 0;
      transition: .4s ease-out; }
    #aside-playlists .video::after {
      content: "";
      top: 50%;
      left: calc(50% + 1.5rem);
      transform: translate(-50%, -50%);
      border-left: 3rem solid white;
      border-top: 3rem solid transparent;
      border-right: 3rem solid transparent;
      border-bottom: 3rem solid transparent;
      position: absolute;
      z-index: 2;
      opacity: 0;
      transition: .4s ease-out; }
    #aside-playlists .video img {
      min-width: 100%;
      min-height: 5rem;
      position: absolute;
      height: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }

#short-links {
  width: 62%;
  display: inline-block;
  vertical-align: middle;
  margin-top: 1rem; }
  #short-links > p {
    color: #374862;
    font-size: 2.1rem;
    font-weight: 400;
    font-family: "Roboto Condensed", Arial, Tahoma, Verdana, Helvetica, sans-serif; }
  #short-links > div {
    background: whitesmoke url("../../images/raspail/bg_short_links.png") no-repeat 22rem;
    height: 7.5rem;
    padding: 2.5rem;
    border-bottom: 1px solid #bcbcbc; }
    #short-links > div a {
      display: inline-block;
      vertical-align: middle;
      width: 12.2rem;
      height: 3.2rem;
      text-decoration: none;
      background: #3b87ab;
      padding: 0.6rem 0 0 2.5rem;
      text-transform: uppercase;
      color: #fff;
      font-size: 1.4rem;
      font-family: "Roboto Condensed", Arial, Tahoma, Verdana, Helvetica, sans-serif;
      position: relative; }
      #short-links > div a .infobulle {
        position: absolute;
        opacity: 0;
        padding: 1rem;
        background: #374862;
        width: 12.2rem;
        font-size: 1.2rem;
        text-transform: none;
        left: 0;
        top: 3.4rem; }
        #short-links > div a .infobulle::before {
          content: "";
          position: absolute;
          top: -1rem;
          left: 5.6rem;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 7.5px 10px 7.5px;
          border-color: transparent transparent #374862 transparent; }
      #short-links > div a::before {
        content: "";
        position: absolute;
        left: 1rem;
        top: 1.1rem;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5.5px 0 5.5px 5px;
        border-color: transparent transparent transparent #fff; }
      #short-links > div a:hover {
        background: #a0c623; }
        #short-links > div a:hover .infobulle {
          opacity: 1;
          transform: translateY(1rem); }

body.is-offcanvas-active {
  max-height: 100vh;
  overflow: hidden; }

.offcanvas-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #3b87ab;
  z-index: 999999;
  transition: all 300ms ease;
  transform: translate3d(-100%, 0, 0); }
  @media (min-width: 1025px) {
    .offcanvas-nav {
      display: none; } }
  .offcanvas-nav.is-active {
    transform: translate3d(0%, 0, 0); }

.offcanvas-toggle-bar {
  padding: 2rem;
  text-align: left;
  background: rgba(255, 255, 255, 0.1); }
  .offcanvas-toggle-bar svg {
    fill: #fff;
    stroke: #fff;
    margin-right: auto; }

.offcanvas-nav--nav li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
.offcanvas-nav--nav .js-submenu-toggle {
  margin-left: auto;
  padding: 3rem 2rem;
  background: rgba(1, 1, 1, 0.1); }
  .offcanvas-nav--nav .js-submenu-toggle span {
    width: 2rem;
    height: 0.2rem;
    display: block;
    transform-origin: top;
    transition: all 300ms ease; }
  .offcanvas-nav--nav .js-submenu-toggle span:nth-child(1) {
    transform: rotate(0deg) translate3d(0%, 50%, 0);
    background: #fff; }
  .offcanvas-nav--nav .js-submenu-toggle span:nth-child(2) {
    transform: rotate(90deg) translate3d(0%, 0%, 0);
    background: #fff; }
  .offcanvas-nav--nav .js-submenu-toggle.is-active span:nth-child(1) {
    transform: rotate(45deg) translate3d(0px, 0px, 0px); }
  .offcanvas-nav--nav .js-submenu-toggle.is-active span:nth-child(2) {
    transform: rotate(-45deg) translate3d(0px, -3px, 0px); }
.offcanvas-nav--nav a {
  color: inherit;
  padding: 2rem;
  display: block;
  text-decoration: none; }
.offcanvas-nav--nav ul {
  display: none;
  flex: 1 1 auto;
  width: 100%; }
  .offcanvas-nav--nav ul.is-active {
    display: block; }
  .offcanvas-nav--nav ul li {
    border-left: 10px solid rgba(1, 1, 1, 0.1); }
    .offcanvas-nav--nav ul li:first-child {
      border-top: 1px solid rgba(255, 255, 255, 0.1); }
    .offcanvas-nav--nav ul li:last-child {
      border-bottom: none; }
.offcanvas-nav--nav ul ul li {
  border-left: 20px solid rgba(1, 1, 1, 0.1); }

.zoneHeaderMobile {
  display: block; }
  @media (min-width: 1025px) {
    .zoneHeaderMobile {
      display: none; } }
  .zoneHeaderMobile-main {
    padding: 0.5rem 2rem;
    display: flex;
    align-items: center; }
  .zoneHeaderMobile-toggle {
    margin-right: 2rem;
    cursor: pointer; }
    .zoneHeaderMobile-toggle span {
      display: block;
      width: 3.6rem;
      height: 0.4rem;
      margin: 0.5rem 0;
      background: #3b87ab; }
    .zoneHeaderMobile-toggle:hover span {
      background: #a0c623; }
  .zoneHeaderMobile-logo {
    margin-right: auto; }
    .zoneHeaderMobile-logo > a {
      display: flex;
      align-items: center;
      text-decoration: none; }
    .zoneHeaderMobile-logo .logo-text-container {
      display: none;
      margin-left: 2rem;
      max-width: 25rem; }
      @media (min-width: 801px) {
        .zoneHeaderMobile-logo .logo-text-container {
          display: block; } }
    .zoneHeaderMobile-logo img {
      max-height: 6.6rem; }
    .zoneHeaderMobile-logo h2 {
      color: #215677;
      font-size: 2.4rem;
      font-weight: 400;
      text-transform: uppercase;
      font-family: "Roboto Condensed", Arial, Tahoma, Verdana, Helvetica, sans-serif;
      text-decoration: none; }
    .zoneHeaderMobile-logo span {
      color: #8d8d8d;
      text-decoration: none;
      font-size: 1.3rem;
      font-weight: 300; }
  .zoneHeaderMobile-links a {
    display: block;
    text-decoration: none;
    background: #3b87ab;
    padding: 0.5rem 1.6rem;
    text-transform: uppercase;
    color: #fff;
    font-size: 1.4rem;
    font-family: "Roboto Condensed", Arial, Tahoma, Verdana, Helvetica, sans-serif;
    position: relative;
    margin: 0.5rem 0; }
    @media (min-width: 441px) {
      .zoneHeaderMobile-links a {
        padding: 0.6rem 0 0 2.5rem;
        width: 12.2rem;
        height: 3.2rem; }
        .zoneHeaderMobile-links a:before {
          content: "";
          position: absolute;
          left: 1rem;
          top: 1.1rem;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5.5px 0 5.5px 5px;
          border-color: transparent transparent transparent #fff; } }
    @media (min-width: 801px) {
      .zoneHeaderMobile-links a {
        display: inline-block;
        margin: 0 0 0 0.5rem; } }
    .zoneHeaderMobile-links a:hover {
      background: #a0c623; }
  .zoneHeaderMobile-search {
    background: #3b87ab;
    padding: 1rem 2rem; }
    .zoneHeaderMobile-search p {
      display: flex;
      flex-wrap: nowrap;
      position: relative; }
    .zoneHeaderMobile-search input {
      line-height: 3.6rem;
      border: none;
      font-size: 1.6rem; }
    .zoneHeaderMobile-search i {
      position: absolute;
      left: 2rem;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      color: #a0c623;
      font-size: 2rem; }
    .zoneHeaderMobile-search input[type="text"] {
      flex: 1 1 auto;
      padding-left: 4rem; }
    .zoneHeaderMobile-search input[type="submit"] {
      width: 10rem;
      background: #a0c623;
      text-transform: uppercase;
      color: #fff;
      font-size: 1.4rem;
      font-family: "Roboto Condensed", Arial, Tahoma, Verdana, Helvetica, sans-serif;
      cursor: pointer; }

.social-sticky {
  position: fixed;
  top: calc(100vh - 5rem);
  z-index: 99999999;
  background: #3b87ab;
  color: #fff;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex; }
  @media (min-width: 1025px) {
    .social-sticky {
      display: none; } }
  .social-sticky a {
    display: flex;
    align-items: center;
    color: #fff;
    display: block;
    flex: 1 1 auto;
    text-align: center;
    border-right: 1px solid rgba(255, 255, 255, 0.1); }
    .social-sticky a:last-of-type {
      border: none; }
    .social-sticky a:hover {
      background: rgba(255, 255, 255, 0.05); }
  .social-sticky i.wgf[class*=" wgf-"] {
    align-self: center;
    line-height: 5rem; }
